<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDateWithBackBtn="true" customer="true" />

    <v-row>
      <v-col md="8" cols="12">
        <h1>PROJECT CHECKLIST</h1>
      </v-col>

      <v-col md="4" cols="12" class="text-right">
        <v-btn
          color="primary"
          class="mr-2 remaining-btn"
          @click="toggleFiltered"
          >{{ filterButton.text }}
        </v-btn>

        <v-btn color="primary" class="spanish-btn">Spanish </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="filterItems"
          class="elevation-1 cursor-pointer"
        >
          <template v-slot:item.yOrNa="{ item }">
            <div class="d-flex">
              <div
                v-for="(val, key) in list"
                :key="val"
                @change="filterItem"
                class="radio-yesNa"
              >
                <input
                  type="radio"
                  :checked="item.yOrNa === val"
                  :id="val"
                  @click="uncheck(val, item)"
                  :key="'input' + key"
                />
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-checkbox
          v-model="checkbox1"
          label="Foreman confirms all items have been completed."
        ></v-checkbox>
        <v-checkbox
          v-model="checkbox2"
          label="I have summarized these items with the customer."
        ></v-checkbox>
        <v-textarea v-model="comments" outlined label="Comments"></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";
import ripple from "vuetify/lib/directives";

export default {
  name: "TodayChecklist",
  components: { CustomerInfo },
  directives: {
    ripple,
  },
  data() {
    return {
      checkbox1: "",
      checkbox2: "",
      comments: "",
      filterButton: { text: "Remaining" },
      filterItems: [],
      list: ["yes", "na"],
      headers: [
        { text: "Y or NA", value: "yOrNa" },
        { text: "Type", value: "type" },
        { text: "Item", value: "item" },
      ],
      items: [
        {
          id: 1,
          yOrNa: "yes",
          type: "Crack Repair",
          item: "Patch (mortar) related cracks too small for porting - to minimize epoxy leaks",
        },
        {
          id: 2,
          yOrNa: "yes",
          type: "Crack Repair",
          item: "Clean up residual epoxy - wipes",
        },
        {
          id: 3,
          yOrNa: "na",
          type: "Crack Repair",
          item: "Patch outside cracks to minimize seepage",
        },
        {
          id: 4,
          yOrNa: null,
          type: "Exterior Drainage",
          item: "Obstructions are worked around",
        },
        {
          id: 5,
          yOrNa: null,
          type: "Exterior Drainage",
          item: "Tarps are used to protect grass / minimize mess",
        },
        {
          id: 6,
          yOrNa: null,
          type: "Exterior Drainage",
          item: "Drainage plan is logical with typography / proper fall",
        },
        {
          id: 7,
          yOrNa: null,
          type: "Exterior Drainage",
          item: "Sod is carefully dug up & saved for proper replacement",
        },
      ],
    };
  },
  created() {
    this.filterItems = this.items;
  },
  methods: {
    toggleFiltered() {
      if (this.filterButton.text === "Remaining") {
        this.filterItems = this.items.filter((item) => item.yOrNa === null);
      } else {
        this.filterItems = this.items;
      }
      this.filterButton.text =
        this.filterButton.text === "Full List" ? "Remaining" : "Full List";
    },
    filterItem() {
      if (this.filterButton.text !== "Remaining") {
        this.filterItems = this.items.filter((item) => item.yOrNa === null);
      }
    },
    uncheck: function (val, item) {
      const index = this.items.findIndex((checked) => checked.id === item.id);
      if (val === item.yOrNa) {
        item.yOrNa = false;
      } else {
        item.yOrNa = val;
      }
      this.items[index] = item;
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  border: 0px;
  width: 100%;
  height: 18px;
}

input[type="radio"]:checked {
  background: #1976d2;
}

.radio-yesNa:first-child input[type="radio"] {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 2px solid #a9a9a9;
  border: 2px solid white;
  width: 14px;
  height: 14px;
  transform: scale(1);
}

.radio-yesNa:first-child input[type="radio"]:focus {
  outline-offset: 0;
}

.radio-yesNa:first-child input[type="radio"]:checked {
  background: #1976d2;
}

.radio-yesNa {
  width: 30px;
}

@media only screen and (max-width: 387px) {
  add-btn {
    margin: 10px !important;
  }
}
</style>
